import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
  CheckBadgeIcon,
  UserGroupIcon,
  BookOpenIcon,
  PhoneArrowDownLeftIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import "./style.css";
import { GoHome } from 'react-icons/go';
import { GiConvergenceTarget } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';
import { FaRegHandshake } from 'react-icons/fa';
import { BsTrophy } from 'react-icons/bs';

const solutions = [{
  name: 'Home',
  description: 'Build strategic funnels that will drive your customers to convert',
  href: '/',
  icon: GoHome,
},
{
  name: 'Approach',
  description: 'Get a better understanding of where your traffic is coming from.',
  href: '/approchprincipal',
  icon: GiConvergenceTarget,
},
{
  name: 'Team',
  description: 'Speak directly to your customers in a more meaningful way.',
  href: '/teammethodology',
  icon: RiTeamLine,
},
{
  name: 'Why Join Us',
  description: "Connect with third-party tools that you're already using.",
  href: '/whyjoinus',
  icon: FaRegHandshake,
},
{ name: 'About Us', description: "Your customers' data will be safe and secure.", href: '/aboutus', icon: BsTrophy },

]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]
const resources = [
  {
    name: 'Solution & Service',
    description: 'TASPro business systems are developed with the following design principals.',
    href: '/solutionservices',
    icon: CheckBadgeIcon,
  },
  {
    name: 'End to End IT SOlution',
    description: 'At TASPro, we strongly focus on business outcome, thus we treat technology as a value driver rather as constraint. ',
    href: '/endtoenditsolution',
    icon: BookmarkSquareIcon,
  },
  {
    name: 'TASPro Team',
    description: 'TASPro professional management team is armed with 40 years of collective hands-on technical experience',
    href: '/team',
    icon: UserGroupIcon,
  },
  {
    name: 'Contact',
    description: '',
    href: '/contact',
    icon: PhoneArrowDownLeftIcon,
  },
]
const recentPosts = [
  { id: 1, name: 'Boost your conversion rate', href: '#' },
  { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
  { id: 3, name: 'Improve your customer experience', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {

  // sticky header
  const [navbar, setNavbar] = useState(false);
  // sticky header
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  window.addEventListener("scroll", changeBackground)

  return (
    <div className={navbar ? 'navbar active' : 'navbar'}>
      <Popover className="relative ">
        <div className="mx-auto max-w-7xl px-4 sm:px-6">
          <div className="flex items-center justify-between    lg:justify-start lg:space-x-10">
            <div className="flex justify-start xl:w-0 xl:flex-1 ">
              <a href="/" className="text-base  hover:text-[#10426b] top-2  font-normal relative">


                <span className="sr-only">Your Company</span>
                <img
                  className="w-[100px]  md:block  "
                  src="images/logo/TASPro Logo.png"
                  alt=""
                />
              </a>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md  p-2  hover:bg-grey-100 hover:text-[#10426b] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#10426b]">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">

              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-grey-600' : 'text-grey-500',
                        'group inline-flex items-center rounded-md  text-base font-medium hover:text-grey-500  p-1 focus:outline-none focus:ring-2 focus:ring-[#10426b]'
                      )}
                    >
                      {/*  */}
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                        <div className="overflow-hidden rounded-lg shadow-lg ">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {solutions.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                              >
                                <item.icon className="h-6 w-6 flex-shrink-0 text-[#10426b]" aria-hidden="true" />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                              </a>
                            ))}
                          </div>
                          <div className="space-y-6 bg-gray-50 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                            {callsToAction.map((item) => (
                              <div key={item.name} className="flow-root">
                                <a
                                  href={item.href}
                                  className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                                >
                                  <item.icon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                  <span className="ml-3">{item.name}</span>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <div className='hidden md:flex gap-5 items-center m-auto'>
                <a href="/" className="text-base font-medium  hover:text-[#10426b] top-2   relative hover:scale-110 transition hover:ease-in-out  duration-500 hover:border-y-2 p-2 ">
                  Home
                </a>
                <a href="/approchprincipal" className="text-base  hover:text-[#10426b] top-2  font-medium relative hover:scale-110 transition hover:ease-in-out  duration-500 hover:border-y-2 p-2 ">
                  Approach
                </a>
                <a href="/teammethodology" className="text-base  hover:text-[#10426b] top-2  font-medium relative hover:scale-110 transition hover:ease-in-out  duration-500 hover:border-y-2 p-2 ">
                  Team
                </a>
                <a href="/whyjoinus" className="text-base  hover:text-[#10426b] top-2  font-medium relative hover:scale-110 transition hover:ease-in-out  duration-500 hover:border-y-2 p-2 ">
                  Why Join Us
                </a>
                <a href="/aboutus" className="text-base  font-medium   hover:text-[#10426b]  top-2 relative  hover:scale-110 transition hover:ease-in-out  duration-500 hover:border-y-2 p-2  ">
                  About us
                </a>
              </div>


              <Popover className="relative mt-1 ">
                {({ open }) => (
                  <>
                    <div className='hover:scale-110 transition hover:ease-in-out  duration-500 hover:border-y-2  '>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-grey-600' : 'text-grey-500',
                          'group inline-flex items-center rounded-md  text-base font-medium hover:text-[#10426b]  '
                        )}
                      >
                        <span className=' font-medium pt-2  mt-2 '>More</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-[#10426b]' : 'text-[#aeaeae]',
                            ' mt-4 ml-1  h-5 w-5 '
                          )}

                        />
                      </Popover.Button>
                    </div>
                    {/* hover:scale-110 transition hover:ease-in-out  duration-300 hover:border-y-2 p-2 */}
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            {resources.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="-m-3 flex items-start rounded-lg p-3 hover:bg-blue-50 hover:scale-105 transition duration-300"
                              >
                                <item.icon className="h-6 w-6 flex-shrink-0 text-[#10426b]" aria-hidden="true" />
                                <div className="ml-4">
                                  <p className="text-base font-bold text-[#10426b]">{item.name}</p>
                                  <p className="mt-1 text-sm font-light text-neutral-500">{item.description}</p>
                                </div>
                              </a>
                            ))}
                          </div>

                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>





            <div className="hidden items-center justify-end lg:flex lg:flex-1 lg:w-0">

              <a
                href="/contact"
                className=" font-semibold ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-[#10426b] px-4 py-2 text-base  text-white shadow-sm hover:bg-[#10426b]"
              >
                Contact us
              </a>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden">
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src="images/logo/TASPro Logo.png"
                      alt="Your Company"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#10426b]">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {solutions.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                      >
                        <item.icon className="h-6 w-6 flex-shrink-0 text-[#10426b]" aria-hidden="true" />
                        <span className="ml-3 text-base font-medium text-gray-900">{item.name}</span>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="space-y-6 py-6 px-5">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">

                  {resources.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div>
                  <a
                    href="/contact "
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-[#10426b] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#10426b]"
                  >
                    Contact Us
                  </a>

                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}
