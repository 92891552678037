import React from 'react'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

function SolutionandService() {
    return (
        <div className='container m-auto   pt-16 pb-16
         rounded-xl 
        '>




            <div className='  w-full max-h-full lg:h-[700px] '>
                <div className='w-full h-full block lg:flex items-start  '>
                    <Fade left>
                        <div className='w-full h-[300px] lg:w-2/6 lg:h-full bg-project_presentation bg-no-repeat bg-cover bg-center    rounded-md'>
                            <div className=' w-full h-full bg-gradient-to-r from-[#0a3050] via-[#1d5a8b] to-[#b1d7f7] opacity-40  mix-blend-normal rounded-md  '></div>
                        </div>
                    </Fade>
                    <div className='w-full h-full  flex flex-col px-4'>
                        <div className='w-full h-2/6    flex justify-start items-start  '>
                            <div className='w-full '>
                                <Fade bottom>
                                    <h1 className=' pt-4 text-3xl sm:text-5xl  font-bold text-[#10426b] mb-4'>TASPro Business Systems</h1>
                                </Fade>
                                <Fade top>

                                    <h1 className='font-poppins text-base  font-normal text-neutral-500 tracking-wide leading-8 sm:leading-8  '>TASPro Technologies with primary focus of financial services in APAC region has been implementing IT solutions for financial advisory services for over 19 years.
                                        TASPro has extensive experience and wealth of industry knowledge in key business functions such as customer relationship, life and general policy handling, advisers and staff maintenance, commission calculations and investment portfolio management.</h1>
                                </Fade>
                            </div>
                        </div>
                        <Zoom cascade>
                            <div className='w-full   h-4/6 flex flex-col md:flex md:flex-row m-auto items-center md:space-x-8 space-y-4 md:space-y-0  '>
                                <div className='w-10/12 md:w-1/3 h-5/6 bg-[#f4faff] bg-opacity-90  shadow-md transform duration-500 ease-in-out hover:scale-105 rounded-md hover:shadow-2xl p-10 
                            hover:bg-[#d4f0fc] hover:bg-opacity-80 group group-hover:shadow-xl hover:-translate-y-1
                            '>
                                    <div className='group-hover:-translate-y-2 transform duration-300 '>
                                        <img src='\images\approchPrinciple\IT Compitancy\clientManagement.png' alt='' className='' />
                                        <h1 className='text-base sm:text-xl font-bold text-[#10426b] text-left pb-3   duration-300 ease-in-out transform    group-hover:'>Support</h1>
                                        <p className='font-poppins text-sm font-light text-neutral-500 text-left  transform duration-300 ease-in-out'>Robust & Sustainable Solution</p>
                                    </div>
                                </div>
                                <div className='w-10/12 md:w-1/3 h-5/6 bg-[#f4faff] bg-opacity-90  shadow-md transform duration-500 ease-in-out hover:scale-105 rounded-md hover:shadow-2xl p-10  
                            hover:bg-[#d4f0fc] hover:bg-opacity-80 group group-hover:shadow-xl hover:-translate-y-1
                            '>
                                    <div className='group-hover:-translate-y-2 transform duration-300 '>
                                        <img src='\images\a6\enable-2.png' alt='' className='' />
                                        <h1 className='text-base sm:text-xl font-bold text-[#10426b] text-left pb-3  duration-300 ease-in-out transform    '>Enable</h1>
                                        <p className='font-poppins text-sm font-light text-neutral-500 text-left  transform duration-300 ease-in-out'>Processing in operational Excellence</p>
                                    </div>
                                </div>
                                <div className='w-10/12 md:w-1/3 h-5/6 bg-[#f4faff] bg-opacity-90  shadow-md transform duration-500 ease-in-out hover:scale-105 rounded-md hover:shadow-2xl p-10 
                            hover:bg-[#d4f0fc] hover:bg-opacity-80 group group-hover:shadow-xl hover:-translate-y-1
                            '>
                                    <div className='group-hover:-translate-y-2 transform duration-300 '>
                                        <img src='\images\a6\transform.png' />
                                        <h1 className='text-base sm:text-xl font-bold text-[#10426b] text-left pb-3  duration-300 ease-in-out transform    '>Transform</h1>
                                        <p className='font-poppins text-sm font-light text-neutral-500 text-left  transform duration-300 ease-in-out'>Integrated & Streamlined Solution</p>
                                    </div>
                                </div>
                            </div>
                        </Zoom>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default SolutionandService