import React from 'react';
import {
  FaDribbbleSquare,
  FaGithubSquare,
  FaLinkedin,

} from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      <div className='bg-[#10426b]'>
        <div className='container md:flex md:flex-row flex flex-col items-center  m-auto  md:px-20 pt-16 pb-16'>
          <div className="md:w-1/2 w-full px-4 md:px-0 ">
            <h1 className='text-xl font-bold  text-white '>TASPro Technologies</h1>
            <p className="font-poppins text-base  font-normal  text-gray-400  leading-8 sm:leading-8 pr-0 sm:pr-4 md:pr-8 xl:pr-16">Since 2003, providing a business focused, professional and practical end to end technology solutions to ease business problems. Our solutions are tactical, analytical, strategical with focus on one primary goal.</p>
            <div className='flex justify-start gap-2 md:w-[75%] my-6 text-white'>

              <a href="https://www.linkedin.com/in/taspro-technologies-india-private-limited-47927018a/"><FaLinkedin size={30} /></a>

            </div>
          </div>


          <div className='w-full md:w-1/2 flex justify-around  px-4 space-x-8 md:space-x-24  '>
            <div className="w-1/2">
              <h6 className='font-medium text-gray-100 '>Quick Links</h6>
              <ul>
                <li className='py-2 text-sm'>
                  <a href="/" className="text-base font-poppins font-normal  text-gray-400 hover:text-white  top-2 relative">
                    Home
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/approchprincipal" className="text-base font-poppins font-normal text-gray-400 hover:text-white top-2 relative">
                    Approach & Principal
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/teammethodology" className="text-base font-poppins font-normal text-gray-400 hover:text-white top-2 relative">
                    Team & Methodology
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/endtoenditsolution" className="text-base font-poppins font-normal text-gray-400 hover:text-white  top-2 relative">
                    End to End IT Solution
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/solutionservices" className="text-base font-poppins font-normal text-gray-400 hover:text-white     top-2 relative">
                    Solution Services
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-1/2">
              <h6 className='font-medium text-gray-100'>Company</h6>
              <ul>
                <li className='py-2 text-sm'>
                  <a href="/aboutus" className="text-base font-poppins font-normal text-gray-400 hover:text-white top-2 relative">
                    About
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/blog" className="text-base font-poppins font-normal text-gray-400 hover:text-white top-2 relative">
                    Blog
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/whyjoinus" className="text-base font-poppins font-normal text-gray-400 hover:text-white top-2 relative">
                    Why Join Us
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/contact" className="text-base font-poppins font-normal  text-gray-400 hover:text-white top-2 relative">
                    Contact
                  </a>
                </li>
                <li className='py-2 text-sm'>
                  <a href="/team" className="text-base font-poppins font-normal text-gray-400 hover:text-white top-2 relative">
                    Taspro Team
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
