import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './components/style.css';
import Home from './Pages/Home';
import ApprochPrincipal from './Pages/ApprochPrincipal';
import TeamMethodology from './Pages/TeamMethodology';
import AboutUs from './Pages/AboutUs';
import WhyJoinUs from './Pages/WhyJoinUs';
import SocialLinks from './Pages/SocialLinks';
import Contact from './Pages/Contact';
import SolutionServices from './Pages/SolutionServices';
import EndtoEndITSolution from './Pages/EndtoEndITSolution';
import Team from './Pages/Team';
// import Blog from './Pages/Blog';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import './components/style.css'
import Whatwedopage from './Pages/Whatwedopage';
import Test from './Test';
import Transition from './components/Transition';
import TeamandMethodology from './components/TeamandMethodology';
import { ScrollToTop } from '../src/components/scroll/ScrollToTop';
function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/approchprincipal" element={<ApprochPrincipal />} />
          <Route path="/teammethodology" element={<TeamMethodology />} />
          <Route path="/whyjoinus" element={<WhyJoinUs />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/sociallinks" element={<SocialLinks />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/solutionservices" element={<SolutionServices />} />
          <Route path="/endtoenditsolution" element={<EndtoEndITSolution />} />
          <Route path="/team" element={<Team />} />
          {/* <Route path="/blog" element={<Blog />} /> */}
          <Route path="/Whatwedopage" element={<Whatwedopage />} />
          <Route path="/test" element={<Test />} />
          <Route path="/transition" element={<Transition />} />
          <Route path="/teamandmethodology" element={<TeamandMethodology />} />
        </Routes>
        <ScrollToTop />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;

//  "