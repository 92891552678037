import React from 'react'
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

function WhyJoinUs() {
    return (
        <div>
            <div className=' m-auto bg-joinus5 bg-cover bg-center bg-no-repeat bg-fixed  '>
                <div className='bg-[#10426b] bg-opacity-40 pb-32  '>
                    <div className=' w-full h-[200px] 
                flex flex-col m-auto justify-center items-center 
                bg-gradient-to-b from-[#10426b] to-transparent 
                
                '>
                        <Fade bottom>

                            <h1 className='container text-3xl text-center sm:text-5xl font-bold text-white '>Why Join Us</h1>
                        </Fade>

                    </div>
                    <div className=' flex flex-col sm:flex sm:flex-row'>
                        <div className='container flex m-auto      flex-col    space-y-4 '>
                            <Zoom cascade>


                                <div className='container w-full h-fit space-y-4 px-0 sm:px-6 md:px-16 lg:px-32 '>
                                    <div className='flex flex-col sm:flex sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 m-auto justify-center items-center'>
                                        <div className='w-1/2  h-[90px] bg-[#e9eef1] rounded-md items-center  flex justify-center'>
                                            <h1 className='text-sm m-auto p-2 sm:text-xl font-bold text-[#10426b]   pt-2'> Sustainable project and work stream</h1>
                                        </div>
                                        <div className='w-1/2  h-[90px] bg-[#e9eef1] rounded-md items-center  flex justify-center '>
                                            <h1 className='text-sm m-auto p-2 sm:text-xl font-bold text-[#10426b]  pt-2'> No single point sensitivity</h1>
                                        </div>
                                    </div>
                                    <div className='flex flex-col sm:flex sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 m-auto justify-center items-center '>
                                        <div className='w-1/2  h-[90px] bg-[#e9eef1] rounded-md items-center  flex justify-center'>
                                            <h1 className='text-sm m-auto p-2 sm:text-xl font-bold text-[#10426b]  pt-2'> Maximise Individual Contributions</h1>
                                        </div>
                                        <div className='w-1/2  h-[90px] bg-[#e9eef1] rounded-md items-center  flex justify-center'>
                                            <h1 className='text-sm m-auto p-2 sm:text-xl font-bold text-[#10426b]  pt-2'>Proactive IT team</h1>
                                        </div>
                                    </div>
                                </div>


                            </Zoom>
                        </div>

                    </div>
                </div>
            </div>



            <div>
                <section class="text-gray-600 body-font">
                    <div class="container px-5 py-24 mx-auto">
                        <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                            {/* <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Why join us?</h1> */}
                        </div>
                        <div class="flex flex-wrap -m-4">
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                        </svg>
                                    </div>
                                    <h2 class="text-lg text-gray-900 font-medium title-font mb-2"> </h2>
                                    <p class="leading-relaxed text-base">Well backed by Singapore based Fintech company. </p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <circle cx="6" cy="6" r="3"></circle>
                                            <circle cx="6" cy="18" r="3"></circle>
                                            <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                                        </svg>
                                    </div>
                                    <h2 class="text-lg text-gray-900 font-medium title-font mb-2"></h2>
                                    <p class="leading-relaxed text-base">Constant stream of projects.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Managed by hardcore IT veterans with collective experience of 50+ years.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Empowering Leadership with vision to ensure business success through technology and strong IT team..</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Investment in continuous learning and upskilling.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Excellent opportunity for hands-on experience across full spectrum of IT development from analysis to implementation.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Work and learn with compact and resourceful team.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Industry standard remuneration.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Company funded learning opportunities.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Onsite deputations for fitting candidates.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Full guidance from senior team.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Cutting edge technology and full stack development opportunities.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">We treat your success as our success.</p>
                                </div>
                            </div>
                            <div class="xl:w-1/3 md:w-1/2 p-4">
                                <div class="border border-gray-200 p-6 rounded-lg">
                                    <div class="w-10 h-10 inline-flex items-center justify-center rounded-full bg-blue-100 text-blue-500 mb-4">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-6 h-6" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <p class="leading-relaxed text-base">Work with a ‘fall forward’ attitude.</p>
                                </div>
                            </div>


                        </div>

                    </div>
                </section>
            </div>
        </div>
    )
}

export default WhyJoinUs