import React from 'react'

function Whatwedopage() {
    return (
        <div>
            <div className='container flex m-auto px-20  relative  rounded-xl  mb-16 '
            >

                <div className='flex flex-col w-1/2 justify-center '
                >
                    <h1 className='text-3xl  font-bold text-[#10426b] sm:text-4xl tracking-normal w-1/2 mb-2  mix-blend-normal'>What we do?</h1>
                    <p className='text-base leading-9 tracking-normal text-gray-500'>Concentrating primarily on financial services industry, TASPro Technologies is laser focused on delivering purpose built IT systems for financial advisory services and fintech firms in APAC region.</p>
                    <h1 className='text-base leading-9 tracking-normal text-gray-500'>
                        At TASPro, we strongly focus on business outcome, thus we treat technology as a value driver rather as constraint. We provide technical solution that are customized and specific to the business needs. Our business focused technology ranges from Enterprise Business System,application customer experience & engagement, Data management & practices, Decision support systems, Legislation & compliance, Cyber security and Virtual desktop support
                    </h1>

                </div>
                <div className='w-1/2 bg-transparent'>
                    <img src="/images/business/business2.png" className='w-full h-full bg-transparent ' alt='' />
                </div>
            </div>
            <div className='container px-10 m-auto  justify-start border-2 border-solid border-gray-300 w-1/3 mb-10 rounded-br-xl'>
            </div>
            <div className='container  m-auto px-20'>
                <h1 className='text-3xl  font-bold text-[#10426b] sm:text-4xl tracking-normal w-1/2 mb-2  mix-blend-normal'>KeyFocus Areas</h1>

            </div>

            {/* secton */}

            <section class="text-gray-600 body-font py-10 ">
                <div class="container w-full  m-auto  flex flex-wrap">
                    <div class="flex flex-wrap w-full m-auto">
                        <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6 m-auto">
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                        <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                    </svg>
                                </div>
                                <div class="flex-grow pl-4">
                                    {/* <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider"></h2> */}
                                    <p class="leading-relaxed">Multitenant applications: Customer Relations Management, InsurTech and Advisory management.</p>
                                </div>
                            </div>
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                        <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                    </svg>
                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider"></h2>
                                    <p class="leading-relaxed">Omnichanel customer experience for Fintech firms.</p>
                                </div>
                            </div>
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                        <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                    </svg>
                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider"></h2>
                                    <p class="leading-relaxed">Robust Fintech SaaS platform with cutting-edge AML/CTF integrated platforms.</p>
                                </div>
                            </div>
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                        <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                    </svg>
                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider"></h2>
                                    <p class="leading-relaxed">Business systems development and support: Digital Transformation, Cloud Migration and support.</p>
                                </div>
                            </div>
                            <div class="flex relative pb-12">
                                <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                                    <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                                </div>
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                        <circle cx="12" cy="5" r="3"></circle>
                                        <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                                    </svg>
                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider"></h2>
                                    <p class="leading-relaxed">Data Engineering: Business Intelligence, Data Analytics for strategic and tactical decision making, Custom KPI's and insights.</p>
                                </div>
                            </div>

                            <div class="flex relative">
                                <div class="flex-shrink-0 w-10 h-10 rounded-full bg-blue-500 inline-flex items-center justify-center text-white relative z-10">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                        <path d="M22 4L12 14.01l-3-3"></path>
                                    </svg>
                                </div>
                                <div class="flex-grow pl-4">
                                    <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider"></h2>
                                    <p class="leading-relaxed">Proactive infrastructure and desktop support services.</p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default Whatwedopage