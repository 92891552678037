import React from 'react'
import Fade from 'react-reveal/Fade';
import { AiTwotoneInsurance } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { GrOptimize } from "react-icons/gr";
import { SiGoogletagmanager } from "react-icons/si";
import { RiBankLine } from "react-icons/ri";
import { BsCashCoin } from "react-icons/bs";
import { FaBusinessTime } from "react-icons/fa";

function SolutionServices() {
    return (
        <div>


            <div className=' w-full m-auto bg-solution&services bg-cover bg-top bg-no-repeat bg-fixed '>
                <div className='bg-[#10426b] bg-opacity-40 '>
                    <div className=' w-full h-[500px]  
                flex flex-col m-auto justify-center items-center 
                bg-gradient-to-b from-[#10426b] to-transparent 
                '>
                        <Fade bottom>
                            <h1 className='text-3xl sm:text-5xl font-bold tracking-wide   text-white  pt-10 pb-4 '>Solution & Services</h1>
                        </Fade>
                    </div>
                </div>
            </div>

            {/* 7 blocks */}
            <div className='container  flex flex-wrap w-full m-auto  justify-center gap-5   mt-16 mb-16 border-b-2 pb-16 '>
                <div className='w-[400px] h-[230px] bg-white p-2 shadow-md pl-16 pt-6 flex relative rounded-md'>
                    <AiTwotoneInsurance className='p-4 absolute shadow-lg drop-shadow-md text-xl w-14 h-14 bg-blue-100 -top-2 left-0 rounded-full text-[#10426b]' />
                    <h1 className='font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8 m-auto'>Insurance Business systems  (Life and General) – Product, Plan and LOB registration and organization, Policy administration and handling, commission and remuneration calculations, Business intelligence and analytical reporting.</h1>
                </div>
                <div className='w-[400px] h-[230px] bg-white p-2 shadow-md pl-16 pt-6 flex relative rounded-md'>
                    <BsFillPeopleFill className='p-4 absolute shadow-lg drop-shadow-md text-xl w-14 h-14 bg-blue-100 -top-2 left-0 rounded-full text-[#10426b]' />
                    <h1 className='font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8 m-auto'>Flexi scheme administration for Employee benefits management.</h1>
                </div>
                <div className='w-[400px] h-[230px] bg-white p-2 shadow-md pl-16 pt-6 flex relative rounded-md'>
                    <GrOptimize className='p-4 absolute shadow-lg drop-shadow-md text-xl w-14 h-14 bg-blue-100 -top-2 left-0 rounded-full text-[#10426b]' />
                    <h1 className='font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8  m-auto'>Investment solutions with specialized portfolio capabilities, fee calculations, fund management and trade submission and reconciliation.</h1>
                </div>
                <div className='w-[400px] h-[230px] bg-white p-2 shadow-md pl-16 pt-6 flex relative rounded-md'>
                    <SiGoogletagmanager className='p-4 absolute shadow-lg drop-shadow-md text-xl w-14 h-14 bg-blue-100 -top-2 left-0 rounded-full text-[#10426b]' />
                    <h1 className='font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8  m-auto'>Advisory Management & B2B Services</h1>
                </div>
                <div className='w-[400px] h-[230px] bg-white p-2 shadow-md pl-16 pt-6 flex relative rounded-md'>
                    <RiBankLine className='p-4 absolute shadow-lg drop-shadow-md text-xl w-14 h-14 bg-blue-100 -top-2 left-0 rounded-full text-[#10426b]' />
                    <h1 className='font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8  m-auto'>Private banking.</h1>
                </div>
                <div className='w-[400px] h-[230px] bg-white p-2 shadow-md pl-16 pt-6 flex relative rounded-md'>
                    <BsCashCoin className='p-4 absolute shadow-lg drop-shadow-md text-xl w-14 h-14 bg-blue-100 -top-2 left-0 rounded-full text-[#10426b]' />
                    <h1 className='font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8  m-auto'>Financial planning management systems aiding financial needs analysis and product recommendations.</h1>
                </div>
                <div className='w-[400px] h-[230px] bg-white p-2 shadow-md pl-16 pt-6 flex relative rounded-md'>
                    <FaBusinessTime className='p-4 absolute shadow-lg drop-shadow-md text-xl w-14 h-14 bg-blue-100 -top-2 left-0 rounded-full text-[#10426b]' />
                    <h1 className='font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8  m-auto'>Business process management solutions providing straight through process to address operational efficiency.</h1>
                </div>

            </div>



            <div className='container flex gap-4 m-auto'>

                <h1 className='text-3xl sm:text-5xl font-bold tracking-wide m-auto text-center  text-[#10426b] pb-2 '>TASPro business systems</h1>
            </div>

            <section class="text-gray-600 body-font mb-16">
                <div class="container px-5  mx-auto flex flex-wrap">
                    <div class="lg:w-1/2 w-full  rounded-lg overflow-hidden m-auto">
                        <img alt="feature" class="object-contain object-center " src="images/business/businesssystem.png" />
                    </div>

                    <div class="flex flex-col flex-wrap lg:py-6 mt-10 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">

                        <div class="flex flex-col mb-10 lg:items-start items-center">


                            <div class="flex-grow">
                                <h2 class="text-base sm:text-xl font-bold text-[#10426b] mb-3">Support</h2>
                                <p class="font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8">To build (cost effective) a robust and sustainable solution to support operational needs and client management activities for advisers.</p>

                            </div>
                        </div>
                        <div class="flex flex-col mb-10 lg:items-start items-center">

                            <div class="flex-grow">
                                <h2 class="text-base sm:text-xl font-bold text-[#10426b] mb-3">Enable</h2>
                                <p class="font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8">Continuous development focusing on enterprise solution to enable automation and straight through processing in operational excellence and better client relationship management.</p>

                            </div>
                        </div>
                        <div class="flex flex-col mb-10 lg:items-start items-center">


                            <div class="flex-grow">
                                <h2 class="text-base sm:text-xl font-bold text-[#10426b] mb-3">Transform</h2>
                                <p class="font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8">Provide an Integrated and streamlined solution to aid business transformation and entrepreneurial financial advice.</p>
                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default SolutionServices