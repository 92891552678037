import React from 'react'
import Fade from 'react-reveal/Fade';

function EndtoEndITSolution() {
    return (
        <div className=' m-auto  '>

            <div>
                <div className=' w-full m-auto bg-endtoend3 bg-cover bg-center bg-no-repeat bg-fixed '>
                    <div className='bg-[#10426b] bg-opacity-40 '>
                        <div className=' w-full h-[500px]  
                flex flex-col m-auto justify-center items-center 
                bg-gradient-to-b from-[#10426b] to-transparent 
                '>
                            <Fade bottom>
                                <h1 className='text-3xl sm:text-5xl font-bold tracking-wide  text-white  pt-10 pb-4 '>End to End IT Solution</h1>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>


            {/* 1-technology innovation */}
            <div className='container m-auto px-6 py-10'>
                <div>
                    <h1 className='text-3xl sm:text-5xl font-bold tracking-wide  text-[#10426b] mb-4 mt-16'>
                        1.Technology Innovation
                    </h1>

                    <p className='font-poppins text-base  font-normal  text-neutral-500  leading-8 sm:leading-8 pr-0 sm:pr-16'>
                        Taspro serves as a mindful visionary to help connect the dots (from end-to-end) and pursue strategic technology developments that enable you to continuously innovate the skill set and realize value.
                    </p>
                    <p className='font-poppins text-base  font-normal  text-neutral-500  leading-8 sm:leading-8 pr-0 sm:pr-16'>We view technologies as building blocks. While they may perform a certain function on their own, their value is limited until they combine with others. By combining these building blocks for a specific use case, we can design a custom solution and, as patterns emerge through subsequent use cases, develop reusable solutions, creating an environment of ongoing innovation and improvement for our Fintech partners</p>
                </div>

                {/* card group */}
                <div>
                    <section class="text-gray-600 body-font">
                        <div class="container  py-12 mx-auto">
                            <div class="flex flex-wrap w-full mb-2">
                                <div class="lg:w-full w-full mb-6 lg:mb-0">
                                    <h1 class="text-base sm:text-xl font-bold text-[#10426b] mb-6">Explore some of the key technologies and methods we deliver in Taspro:</h1>

                                </div>

                            </div>
                            <div class="flex flex-wrap justify-center -m-4">
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <div class="bg-white p-6 rounded-lg h-[435px]  shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-40 rounded w-full object-cover object-center mb-6" src="/images/a6/agileService.png" alt="content" />
                                        <h3 class="text-base sm:text-xl font-bold text-[#10426b]">AGILE SERVICES </h3>
                                        <p class="font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8">helps transition to agile operating models that drive digital transformation.</p>
                                    </div>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <div class="bg-white p-6 rounded-lg h-[435px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-40 rounded w-full object-cover object-center mb-6" src="/images/a6/cloud.png" alt="content" />
                                        <h3 class="text-base sm:text-xl font-bold text-[#10426b]">CLOUD NATIVE</h3>
                                        <p class="font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8">solutions helps transform the application development capabilities, creating a cloud-based environment that speeds new product and service delivery.</p>
                                    </div>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <div class="bg-white p-6 rounded-lg h-[435px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-40 rounded w-full object-contain object-center mb-6" src="/images/a6/devOps.png" alt="content" />
                                        <h3 class="text-base sm:text-xl font-bold text-[#10426b]">DEV-OPS</h3>
                                        <p class="font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8">DEV-OPS is an end-to-end IT delivery approach that makes business change faster, more flexible, and more reliable.</p>
                                    </div>
                                </div>
                                <div class="xl:w-1/3 md:w-1/2 p-4">
                                    <div class="bg-white p-6 rounded-lg h-[465px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-40 rounded w-full object-cover object-center mb-6" src="/images/a6/innovationManagement.png" alt="content" />
                                        <h3 class="text-base sm:text-xl font-bold text-[#10426b]t">INNOVATION MANAGEMENT</h3>
                                        <p class="font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8">Innovation Management helps you implement processes in a targeted, structured, and strategic manner to harness and monetize creative ideas from within Taspro.</p>
                                    </div>
                                </div>
                                <div class=" xl:w-1/3 md:w-1/2 p-4">
                                    <div class="bg-white p-6 rounded-lg h-[465px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-40 rounded w-full object-cover object-center mb-6" src="/images/a6/apiFirstModel.png" alt="content" />
                                        <h3 class="text-base sm:text-xl font-bold text-[#10426b]">API FIRST MODEL</h3>
                                        <p class="font-poppins text-sm font-light text-gray-800 leading-8 sm:leading-8">API-first means that you treat APIs as critical business assets, understanding the value they bring to the  organization. You design each of your APIs around a contract written in an API description language like OPEN API for consistency, reusability, and broad interoperability.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {/*2- design & product innovation */}
            <div className='container m-auto bg-[#e9eef1] w-full px-6 py-8 rounded-xl'>
                <div>
                    <h1 className='text-3xl sm:text-5xl font-bold tracking-wide  text-[#10426b] mb-4 '>
                        2.Design & Product Innovation:
                    </h1>

                    <p className='font-poppins text-base  font-normal  text-neutral-500  leading-8 sm:leading-8 mt-2 pr-0 sm:pr-16'>
                        Connect with clients and users when you really understand what they need. Our approach to design puts humans at the centre. What are their pain points? What are their real experiences of using your products and services? TASPRO Design & Innovation team who can help generate new ideas, build new products and services from scratch, or improve existing ones with a proactive touchpoint such as
                    </p>
                </div>
                <div>
                    <section class="text-gray-600 body-font">
                        <div class="container px-5  mx-auto">

                            <div class="flex flex-wrap justify-center m-auto">
                                <div class="xl:w-1/4 md:w-1/2 p-4">
                                    <div class="bg-white p-2 rounded-lg h-[300px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-4/6 rounded w-full object-contain object-center mb-6" src="/images/a6/enterpriseInnovation.png" alt="content" />
                                        <p class="font-poppins text-base text-center font-normal  text-[#10426b]  ">Enterprise Innovation.</p>
                                    </div>
                                </div>
                                <div class="xl:w-1/4 md:w-1/2 p-4">
                                    <div class="bg-white p-2 rounded-lg h-[300px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-4/6 rounded w-full object-contain object-center mb-6" src="/images/a6/designInnovation-2.jpg" alt="content" />
                                        <p class="font-poppins text-base text-center font-normal  text-[#10426b]  ">Design to Deliver.</p>
                                    </div>
                                </div>
                                <div class="xl:w-1/4 md:w-1/2 p-4">
                                    <div class="bg-white p-2 rounded-lg h-[300px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-4/6 rounded w-full object-contain object-center mb-6" src="/images/a6/Strategy.png" alt="content" />
                                        <p class="font-poppins text-base text-center font-normal  text-[#10426b]  ">Experience Strategy and Lean Product Development.</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {/* 3.	Technology & Engineering: */}
            <div className='container m-auto w-full px-6 py-10'>
                <div>
                    <h1 className='text-3xl sm:text-5xl font-bold tracking-wide  text-[#10426b] mb-4 '>
                        3.Technology & Engineering:
                    </h1>

                    <p className='font-poppins text-base  font-normal  text-neutral-500  leading-8 sm:leading-8 pr-0 sm:pr-16'>
                        Collaborate with our engineering teams to deliver your most challenging technology programmes with increased agility, velocity, quality and cost-effectiveness. We have a proven track record in designing and building software that has transformed the performance of some of the most demanding Fintech organisations in Singapore and APAC. Our cutting-edge technology features:
                    </p>
                </div>
                <div>
                    <section class="text-gray-600 body-font">
                        <div class="container px-5 py-16 mx-auto">
                            <div class="flex flex-wrap w-full ">

                            </div>
                            <div class="flex flex-wrap justify-center m-auto">
                                <div class="xl:w-1/4 md:w-1/2 p-4">
                                    <div class="bg-white p-6 rounded-lg h-[320px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-40 rounded w-full object-cover object-center mb-6" src="/images/a6/largeScaleEnterprise.png" alt="content" />
                                        <p class="leading-relaxed text-base">Large Scale Enterprise Applications using API First Methodologies.</p>
                                    </div>
                                </div>
                                <div class="xl:w-1/4 md:w-1/2 p-4">
                                    <div class="bg-white p-6 rounded-lg h-[320px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-40 rounded w-full object-cover object-center mb-6" src="/images/a6/appModel-2.jpg" alt="content" />
                                        <p class="leading-relaxed text-base">Application Modernisation with Event-driven capability model.</p>
                                    </div>
                                </div>
                                <div class="w-full xl:w-1/4 md:w-1/2 p-4">
                                    <div class="bg-white p-6 rounded-lg h-[320px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-40 rounded w-full object-contain object-center mb-6" src="/images/a6/productandservicebuild-2.jpg" alt="content" />
                                        <p class="leading-relaxed text-base">Product and Service Build.</p>
                                    </div>
                                </div>
                                <div class="w-full xl:w-1/4 md:w-1/2 p-4">
                                    <div class=" bg-white p-6 rounded-lg h-[320px] shadow-md hover:scale-105 hover:bg-blue-100 transition duration-300 hover:shadow-lg hover:-translate-y-2 hover:bg-opacity-70">
                                        <img class="h-40 rounded w-full object-contain object-center mb-6" src="/images/a6/endtoenddevops.png" alt="content" />
                                        <p class="leading-relaxed text-base">End-to-End Focus DevOps Culture.</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {/* 4-cloud & integration */}
            <div className='container m-auto bg-[#e9eef1]  px-6 py-10 rounded-xl'>
                <h1 className='text-3xl sm:text-5xl font-bold tracking-wide  text-[#10426b] mb-4 '>
                    4.Cloud and Integration Platform:
                </h1>

                <div className='flex flex-col-reverse  md:flex md:flex-row gap-4'>

                    <div className='w-full md:w-2/3 p-4'>


                        <p className='font-poppins text-base  font-normal  text-neutral-500  leading-8 sm:leading-8'>
                            Cloud is the driving engine of digital transformation. The question is how, not if, cloud fits into the business strategy. With our extensive, end-to-end cloud expertise, we are helping the organisation leverage the full power of the cloud to create organisational efficiencies, streamlines processes, reduce costs, and deliver more innovative services.
                        </p>
                        <p className='font-poppins text-base  font-normal  text-neutral-500  leading-8 sm:leading-8'>
                            We invest heavily into our engineers at all stages of their careers, from the graduates and freshers to our cloud-certified engineers and architects. Because of this, we have some of the best talent in the team, able to understand the business and make the right solution to organisation change management programs.
                        </p>
                        <p className='font-poppins text-base  font-normal  text-neutral-500  leading-8 sm:leading-8'>
                            We have partnerships with the main Cloud Service Providers ( Azure and Google Cloud) and have attained a wide range of competencies and specialisations with each one. With this, we have access to supporting programmes like technical bootcamps, trainings and certifications, that Taspro supports to help the Engineers realise the full potential of the cloud.
                        </p>
                    </div>
                    <div className='w-full md:w-1/3'>
                        <img src='/images/a6/cloudIntegration-2.jpg' alt='' />
                    </div>
                </div>
                <div>
                </div>

            </div>
        </div>
    )
}

export default EndtoEndITSolution